<template>
  <el-upload 
    ref="upload"
    accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
    :action="action" 
    :data="uploadData"
    :auto-upload="false"
    :show-file-list="false"
    :before-upload="onBeforeUpload"
    :on-change="onFileChange"
    :on-success="onSuccess"
    :on-error="onError"
  >
    <div class="container" @click="onContainerClick($event)">
      <div 
        v-for="(img, index) in imgList"
        :key="index"
        class="preview"
        :style="`background-image:url(${img})`"
        @click.stop="preview(img)"
      >
        <img class="close-icon" src="https://mktv-in-cdn.mockuai.com/16074168138747446.png" alt="" @click="onDelete(index)">
      </div>
      <div ref="addBtn" v-if="showAdd" class="upload-add"></div>
      <a v-if="example" :href="example" target="_blank" class="example" @click.stop>查看示例</a>
      <div v-if="tips" class="tips">{{ tips }}</div>
    </div>
  </el-upload>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
      default: '',
    },
    example: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      action: '',
      uploadData: {},
      filename: '',
      src: '',
      imgList: [],
    }
  },
  computed: {
    showAdd() {
      return (this.multiple && this.imgList.length < this.max) || (!this.multiple && !this.imgList.length)
    }
  },
  methods: {
    onContainerClick(e) {
      if (e.target !== this.$refs.addBtn) {
        e.stopPropagation();
      }
    },

    onBeforeUpload(file) {
      console.log(file);
    },

    onFileChange(e, fileList) {
      console.log('onFileChange', e, fileList);
      if (e.status === 'ready') {
        this.getUploadParams(e);
      }
    },

    getUploadParams(e) {
      const ext = e.name.split('.').pop();
      const name = `${Date.now()}${e.uid}.${ext}`;

      window.lib.api.post({
        api: 'ec/b/file/upload/auth/get',
        data: {
          filename: name,
        },
        success: (res) => {
          const { host, policy, signature, oss_accesskey_id, filename } = res.data.file_upload_auth;
          this.filename = filename;
          this.action = host;
          this.uploadData = {
            policy,
            signature,
            OSSAccessKeyId: oss_accesskey_id,
            success_action_status: '200',
            name: filename,
            key: filename,
          };
          this.$nextTick(() => {
            this.$refs.upload.submit();
          })
        },
        error: (err) => {
          this.$message.error(err.msg);
        }
      })
    },

    onSuccess(res) {
      console.log('success', res)
      this.src = `${this.action}/${this.filename}`;
      if (this.multiple) {
        this.imgList.push(`${this.action}/${this.filename}`);
      } else {
        this.$set(this.imgList, '0', this.src);
      }
      this.$emit('success', this.src);
    },

    onError(e) {
      this.$emit('error', e);
    },

    onDelete(index) {
      this.$emit('delete', this.multiple ? this.imgList[index] : '')
      this.imgList.splice(index, 1)
    },

    preview(img) {
      console.log(img)
    },

    clearFiles() {
      this.imgList = []
      this.$refs.upload.clearFiles()
    }
  },
}
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .upload-add {
      width: 90px;
      height: 90px;
      background-image: url(https://mktv-in-cdn.mockuai.com/16170761017728997.png);
      background-size: cover;
    }
    .preview {
      position: relative;
      width: 90px;
      height: 90px;
      margin-right: 30px;
      margin-bottom: 15px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid #f7f7f7;
      .close-icon {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .example {
      text-decoration: none;
      margin-left: 20px;
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #007cff;
    }
    .tips {
      margin-left: 20px;
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #999;
    }
  }
  
</style>
